@import '../../styles/variables';

.top-bar {
  background-color: $colorWhite;
  box-shadow: 0 2px 8px -1px $colorBlack30;
  height: 66px;
  padding: 0 15px 0 30px;
  position: sticky;
  text-align: center;

  .tb-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;

    .tb-left-links {
      display: inline-flex;
      align-items: center;

      .tb-title {
        color: $colorPrimary;
        cursor: pointer;
        display: inline-block;
        font-size: $fontSizeLarge;
        letter-spacing: 5.08px;
        font-weight: $fontWeightRegular;
        font-family: serif;

        @media (max-width: #{$maxMobileWidth}) {
          font-size: 18px;
        }
      }

      .tb-desktop-only {
        @media (max-width: $maxMobileWidth) {
          display: none;
        }
      }

      .tb-link {
        color: $colorFont;
        display: inline-block;
        font-size: $fontSizeBase;
        margin-left: 30px;

        &:first-of-type {
          margin-left: 60px;
        }

        &:hover,
        &:active,
        &:focus {
          color: $colorBlack;
        }
      }
    }
  }
}
