@import "../../styles/variables";

.appointments-table {
  margin-top: 40px;
  width: 1305px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: #{$maxMobileWidth}) {
    width: 100%;
  }

  .appointments-header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $colorBlack15;
    height: 40px;
    font-weight: bold;
    align-items: center;

    .at-data-item {
      padding: 0 8px;
    }

    .ah-status {
      width: 80px;
    }

    .ah-date {
      width: 110px;
    }

    .ah-time {
      width: 105px;
    }

    .ah-service {
      width: 220px;
    }

    .ah-amount {
      width: 80px;
    }

    .ah-discount {
      width: 70px;
    }

    .ah-tip {
      width: 95px;
    }

    .ah-notes {
      width: 500px;
    }

    @media (max-width: #{$maxMobileWidth}) {
      .ah-status {
        display: none;
      }
  
      .ah-time {
        display: none;
      }
  
      .ah-discount {
        display: none;
      }
  
      .ah-tip {
        display: none;
      }
  
      .ah-notes {
        display: none;
      }
    }
  }

  .appointment-row {
    display: flex;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: $colorBlack5;
    }

    .appointment-actions {
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: $colorWhite;
      width: 48px;

      .row-actions {
        padding: 0 8px;
        cursor: pointer;
        color: $colorPrimary;

        &:hover {
          color: $colorPrimaryLight;
        }
      }
    }

    .appointment-data {
      display: flex;
      width: 100%;
      padding: 16px 0;
      align-items: center;
      text-decoration: none;
      color: inherit;

      .at-data-item {
        padding: 0 8px;
      }

      &:hover {
        background-color: $colorBlack15;
      }

      .appointment-actions {
        width: 48px;
      }

      .appointment-status {
        width: 85px;
      }

      .appointment-date {
        width: 95px;
      }

      .appointment-time {
        width: 130px;
      }

      .appointment-service {
        width: 200px;
      }

      .appointment-amount {
        width: 80px;
      }

      .appointment-discount {
        width: 70px;
      }

      .appointment-tip {
        width: 95px;
      }

      .appointment-notes {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (max-width: #{$maxMobileWidth}) {
        height: 85px;
    
        .appointment-status {
          display: none;
        }
    
        .appointment-time {
          display: none;
        }
    
        .appointment-discount {
          display: none;
        }
    
        .appointment-tip {
          display: none;
        }
    
        .appointment-notes {
          display: none;
        }
      }
    }
  }
}
