@import "../../styles/variables";

.clients {
  margin-top: 40px;
  width: 1305px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: #{$maxMobileWidth}) {
    width: 100%;
  }

  .clients-header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $colorBlack15;
    height: 40px;
    font-weight: bold;
    align-items: center;

    .ch-name {
      width: 165px;
    }

    .ch-phone {
      width: 140px;
    }

    .ch-email {
      width: 140px;
    }

    .ch-contact {
      width: 65px;
    }

    .ch-allergies {
      width: 135px;
    }

    .ch-waiver {
      width: 50px;
    }

    .ch-history {
      width: 225px;
    }

    .ch-notes {
      width: 320px;
    }

    .ch-actions {
      padding: 8px;
      width: 130px;
    }

    @media (max-width: #{$maxMobileWidth}) {
      .ch-name {
        width: 110px;
      }

      .ch-phone {
        width: 180px;
      }

      .ch-email {
        width: 80px;
      }

      .ch-contact {
        display: none;
      }

      .ch-allergies {
        display: none;
      }

      .ch-waiver {
        display: none;
      }

      .ch-history {
        display: none;
      }

      .ch-notes {
        display: none;
      }
    }
  }

  .client-table-row {
    display: flex;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: $colorBlack5;
    }

    .client-actions {
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: $colorWhite;

      .row-action {
        padding: 0 8px;
        cursor: pointer;

        &:hover {
          color: $colorPrimary;
        }
      }
    }

    .client-data {
      display: flex;
      width: 100%;
      padding: 16px 0;
      align-items: center;
      text-decoration: none;
      color: inherit;
      overflow-wrap: break-word;

      &:hover {
        background-color: $colorBlack15;
      }

      .ct-data-item {
        padding: 0 4px;
      }

      .client-name {
        width: 15%;
      }

      .client-phone {
        width: 115px;
      }

      .client-email {
        width: 160px;
      }

      .client-allergies {
        width: 115px;
      }

      .client-waiver {
        width: 50px;
      }

      .client-history {
        width: 260px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .client-notes {
        width: 320px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (max-width: #{$maxMobileWidth}) {
        font-size: 12px;
        height: 85px;

        .client-name {
          width: 100px;
        }

        .client-phone {
          width: 101px;
        }

        .client-email {
          width: 80px;
        }

        .client-contact {
          display: none;
        }

        .client-allergies {
          display: none;
        }

        .client-waiver {
          display: none;
        }

        .client-history {
          display: none;
        }

        .client-notes {
          display: none;
        }
      }
    }
  }

  .asterisk-info {
    display: flex;
    justify-content: left;
    margin-top: 25px;
    font-style: italic;
    font-size: 12px;
  }
}
