@import '../../styles/variables';

.app {
  margin: -8px;
  color: $colorFont;
  font-size: $fontSizeBase;
  font-weight: $fontWeightLight;
  letter-spacing: $letterSpacingBase;
  text-align: center;
  font-family: sans-serif;

  div {
    box-sizing: border-box;
  }
}
