@import "../../styles/variables";

.clients-dashboard {
  padding: 16px;
  font-size: $fontSizeBase;

  .clients-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .add-client {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      margin-right: 32px;

      svg {
        margin-left: 8px;
        color: $colorPrimary;
        font-size: 32px;
        transition: color $transitionTime;
      }
    }

    @media (max-width: #{$maxMobileWidth}) {
      .add-client {
        margin: -5px 0 0 0;
        font-size: 14px;

        &:hover {
          color: $colorPrimary;
        }

        svg {
          margin-left: 8px;
          color: $colorPrimary;
          font-size: 25px;
          transition: color $transitionTime;
        }
      }
    }
  }

  .modal-wrapper {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
    position: absolute;
    width: 100vw;
    top: 0;

    .delete-modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      border: 1px solid #d9d9d9;
      width: 32rem;
      max-width: 100%;
      height: 16rem;
      max-height: 100%;
      border-radius: 5px;
      box-shadow: 0 0 60px 10px rgb(0 0 0 / 20%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;
      background-color: $colorWhite;
      padding: 16px;
    }
  }
}
