@import '../../styles/variables';

div > h1 {
  font-size: 18px;
  font-weight: 600;
}

div > a > img {
  height: 24px;
  margin-bottom: -8px;
}

div > a {
  text-decoration: none;
  color: $colorPrimary;
}